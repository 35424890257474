import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { SubscriptionGuard } from 'src/app/shared/guards/subscription.guard';
import { HomeComponent } from './home.component';
import { LandingComponent } from '../notification-module/landing/landing.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('../dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'management',
                loadChildren: () =>
                    import('../management/management.module').then(
                        (m) => m.ManagementModule
                    ),
            },
            {
                path: 'organization',
                loadChildren: () =>
                    import('../organization/organization.module').then(
                        (m) => m.OrganizationModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'subscription',
                loadChildren: () =>
                    import('../subscription/subscription.module').then(
                        (m) => m.SubscriptionModule
                    ),
            },
            {
                path: 'task',
                loadChildren: () =>
                    import('../task-management/task-management.module').then(
                        (m) => m.TaskManagementModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'project',
                loadChildren: () =>
                    import('../project-management/project-management.module').then(
                        (m) => m.ProjectManagementModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'drive',
                loadChildren: () =>
                    import('../dms/dms.module').then((m) => m.DmsModule),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'discussion',
                loadChildren: () =>
                    import('../discussion/discussion.module').then(
                        (m) => m.DiscussionModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'notes',
                loadChildren: () =>
                    import('../notes/notes.module').then((m) => m.NotesModule),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'time-sheet',
                loadChildren: () =>
                    import('../time-sheet/time-sheet.module').then(
                        (m) => m.TimeSheetModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'notification',
                loadChildren: () =>
                    import('../notification-module/notification-module.module').then(
                        (m) => m.NotificationModuleModule
                    ),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'user-profile',
                loadChildren: () =>
                    import(
                        '../user-personalized-profile/user-personalized-profile.module'
                    ).then((m) => m.UserPersonalizedProfileModule),
                canActivate: [SubscriptionGuard],
            },
            { path: 'landing', component: LandingComponent },
            {
                path: 'report',
                loadChildren: () =>
                    import('../reports/reports.module').then((m) => m.ReportsModule),
                canActivate: [SubscriptionGuard],
            },
            {
                path: 'leave',
                loadChildren: () =>
                    import('../leave/leave.module').then((m) => m.LeaveModule),
                canActivate: [SubscriptionGuard],
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'organization-settings',
        loadChildren: () =>
            import('../organization-settings/organization-settings.module').then(
                (m) => m.OrganizationSettingsModule
            ),
    },
    {
        path: 'email-management',
        loadChildren: () =>
            import('../email-management/email-management.module').then(
                (m) => m.EmailManagementModule
            ),
        canActivate: [SubscriptionGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomeRoutingModule {}
