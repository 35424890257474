import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { FILE_TYPE } from '../../global/constants';
import { ApiService } from '../api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    setFileSize: any = 5000000;
    canvas: any;
    pieCanvas: any;
    headerCanvas: any;
    constructor(
    private datePipe: DatePipe,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public apisevice: ApiService
    ) {}

    forShortName(name: string): any {
        if (name) {
            if (_.toUpper(name).split(' ').length > 1) {
                return `${_.toUpper(name).split(' ')[0].charAt(0)}${_.toUpper(name)
                    .split(' ')[1]
                    .charAt(0)}`;
            } else {
                return `${_.toUpper(name).substring(0, 2)}`;
            }
        }
    }

    async getDocumentIcon(file: any, is_type: any) {
        let src: string;
        let type: string;
        console.log(file.type);
        switch (file.type) {
            
        case FILE_TYPE.IMAGE_PNG:
        case FILE_TYPE.IMAGE_JPEG:
        case FILE_TYPE.IMAGE_JPG:
        case FILE_TYPE.IMAGE_GIF:
        case FILE_TYPE.IMAGE_SVG:
        case FILE_TYPE.JPEG:
        case FILE_TYPE.PNG:
        case FILE_TYPE.JPG:
        case FILE_TYPE.IMG:
            if (file.path) {
                src = file.path;
            } else if (file.file_path) {
                src = file.file_path;
            } else {
                src = await this.getDataFromBlob(file);
            }
            type = 'Image';
            break;
        case FILE_TYPE.PDF:
        case FILE_TYPE.PDF_FILE:
            src = './assets/images/document-icon/pdf.png';
            type = 'PDF';
            break;
        case FILE_TYPE.SHEET:
        case FILE_TYPE.XLSX:
            src = './assets/images/document-icon/sheets.png';
            type = 'XLSX';
            break;
        case FILE_TYPE.MS_WORD:
        case FILE_TYPE.TEST:
        case FILE_TYPE.DOCUMENT:
        case FILE_TYPE.DOCS:
            src = './assets/images/document-icon/doc.png';
            type = 'DOC';
            break;
        case FILE_TYPE.VIDEO:
        case FILE_TYPE.AUDIO:
        case FILE_TYPE.AUDIO_FILE:
        case FILE_TYPE.VIDEO_FILE:
        case FILE_TYPE.VIDEO_TYPE:
        case FILE_TYPE.VIDEO_QUICKTIME:
        case FILE_TYPE.VIDEO_OGG:
        case FILE_TYPE.AUDIO_WAVE:
        case FILE_TYPE.AUDIO_WAV:    
        case FILE_TYPE.AUDIO_M4A:
            src = './assets/images/document-icon/video-and-audio.png';
            type = 'Video';
            break;
        case FILE_TYPE.ZIP:
        case FILE_TYPE.ZIP_FILE:
            src = './assets/images/document-icon/zip.png';
            type = 'Zip';
            break;
        case FILE_TYPE.CSV:
        case FILE_TYPE.CSV_FILE:
            src = './assets/images/document-icon/csv.png';
            type = 'CSV';
            break;
        case FILE_TYPE.ODT:
        case FILE_TYPE.ODT_FILE:
            src = './assets/images/document-icon/odt.png';
            type = 'ODT';
            break;
        case FILE_TYPE.HTML_FILE:
        case FILE_TYPE.HTML:
            src = './assets/images/document-icon/html.png';
            type = 'HTML';
            break;
        default:
            src = './assets/images/document-icon/default.png';
            type = '';
        }
        const output = is_type ? type : src;
        return output;
    }

    async getDocumentIconTemporary(file: any, is_type: any) {
        let src: string;
        let type: string;
        switch (file.type) {
        case FILE_TYPE.IMAGE_PNG:
        case FILE_TYPE.IMAGE_JPEG:
        case FILE_TYPE.IMAGE_JPG:
        case FILE_TYPE.IMAGE_GIF:
        case FILE_TYPE.IMAGE_SVG:
        case FILE_TYPE.JPEG:
        case FILE_TYPE.PNG:
        case FILE_TYPE.JPG:
        case FILE_TYPE.IMG:
            src = './assets/images/document-icon/default.png';
            type = 'Image';
            break;
        case FILE_TYPE.PDF:
        case FILE_TYPE.PDF_FILE:
            src = './assets/images/document-icon/pdf.png';
            type = 'PDF';
            break;
        case FILE_TYPE.SHEET:
        case FILE_TYPE.XLSX:
            src = './assets/images/document-icon/sheets.png';
            type = 'XLSX';
            break;
        case FILE_TYPE.MS_WORD:
        case FILE_TYPE.TEST:
        case FILE_TYPE.DOCUMENT:
        case FILE_TYPE.DOCS:
            src = './assets/images/document-icon/doc.png';
            type = 'DOC';
            break;
        case FILE_TYPE.VIDEO:
        case FILE_TYPE.AUDIO:
        case FILE_TYPE.AUDIO_FILE:
        case FILE_TYPE.VIDEO_FILE:
        case FILE_TYPE.VIDEO_TYPE:
        case FILE_TYPE.VIDEO_QUICKTIME:
        case FILE_TYPE.VIDEO_OGG:
        case FILE_TYPE.AUDIO_WAVE:
        case FILE_TYPE.AUDIO_M4A:
            src = './assets/images/document-icon/video-and-audio.png';
            type = 'Video';
            break;
        case FILE_TYPE.ZIP:
        case FILE_TYPE.ZIP_FILE:
            src = './assets/images/document-icon/zip.png';
            type = 'Zip';
            break;
        case FILE_TYPE.CSV:
        case FILE_TYPE.CSV_FILE:
            src = './assets/images/document-icon/csv.png';
            type = 'CSV';
            break;
        case FILE_TYPE.ODT:
        case FILE_TYPE.ODT_FILE:
            src = './assets/images/document-icon/odt.png';
            type = 'ODT';
            break;
        case FILE_TYPE.HTML_FILE:
        case FILE_TYPE.HTML:
            src = './assets/images/document-icon/html.png';
            type = 'HTML';
            break;
        default:
            src = './assets/images/document-icon/default.png';
            type = '';
        }
        const output = is_type ? type : src;
        return output;
    }

    getDataFromBlob = (file: any) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                res(reader.result);
            };
            reader.onerror = function (error) {
                rej(error);
            };
        }) as any;
    };

    downloadFile(data: any, fileName: any) {
        const urlBlob = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', `export_${new Date().getTime()}.${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getFileName(fileExtension: any) {
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth();
        const date = d.getDate();
        return (
            'RecordRTC-' +
      year +
      month +
      date +
      '-' +
      this.getRandomString() +
      '.' +
      fileExtension
        );
    }

    getRandomString() {
        if (
            window.crypto?.getRandomValues &&
            navigator.userAgent.indexOf('Safari') === -1
        ) {
            let a = window.crypto.getRandomValues(new Uint32Array(3)),
                token = '';
            for (let i = 0, l = a.length; i < l; i++) {
                token += a[i].toString(36);
            }
            return token;
        } else {
            return (Math.random() * new Date().getTime())
                .toString(36)
                .replace(/\./g, '');
        }
    }

    audioFile(file: any) {
        return new Promise((resolve, _reject) => {
            const reader = new FileReader();
            reader.onload = async (_e: any) => {
                if (file.size) {
                    const docData = {
                        result: reader.result,
                        files: file,
                    };
                    resolve(docData);
                } else {
                    resolve(false);
                }
            };
            reader.readAsDataURL(file);
        });
    }

    getOffsetTime() {
        const offsetInMinutes = new Date().getTimezoneOffset();

        const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const minutes = Math.abs(offsetInMinutes) % 60;

        // Construct a Date object with an arbitrary date value
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        // Format the date using the DatePipe
        const formattedOffset = this.datePipe.transform(date, 'HH:mm');

        // Determine the sign of the offset
        const sign = offsetInMinutes > 0 ? '-' : '+';
        return sign + formattedOffset;
    }

    getFormatedHours(working_hours: any) {
        if (working_hours) {
            const [hours, minutes] = working_hours.split(':');

            // Create a Date object with an arbitrary date
            const date = new Date();
            date.setHours(Number(hours));
            date.setMinutes(Number(minutes));

            // Format the time using the DatePipe
            return this.datePipe.transform(date, 'HH:mm');
        }
        return '00:00';
    }

    addTimesTasks(time1: any, time2: any) {
    // Split time strings into hour, minute, and second components
        let [h1, m1, s1] = time1.split(':').map(Number);
        let [h2, m2, s2] = time2.split(':').map(Number);
        if (!s1) {
            s1 = 0;
        }
        if (!s2) {
            s2 = 0;
        }
        // Add time components together
        const totalSeconds = s1 + s2;
        const totalMinutes = m1 + m2 + Math.floor(totalSeconds / 60);
        const totalHours = h1 + h2 + Math.floor(totalMinutes / 60);
        // Format the result as a string
        const hours = totalHours.toString().padStart(2, '0');
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        const result = `${hours}:${minutes}:${seconds}`;
        return result;
    }

    public addTimes(startTime: any, endTime: any) {
        const times = [0, 0];
        const max = times.length;

        const a = (startTime || '').split(':');
        const b = (endTime || '').split(':');

        // normalize time values
        for (let i = 0; i < max; i++) {
            a[i] = isNaN(parseInt(a[i], 10)) ? 0 : parseInt(a[i], 10);
            b[i] = isNaN(parseInt(b[i], 10)) ? 0 : parseInt(b[i], 10);
        }

        // store time values
        for (let i = 0; i < max; i++) {
            times[i] = a[i] + b[i];
        }

        let minutes = times[0];
        let seconds = times[1];

        if (seconds >= 60) {
            const m = (seconds / 60) | 0;
            minutes += m;
            seconds -= 60 * m;
        }

        if (minutes >= 60) {
            const h = (minutes / 60) | 0;
            minutes -= 60 * h;
        }        

        return ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }

    async uploadDocs(event: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const files = event.target.files || event.clipboardData.files;
            const allDocs = [];

            if (files) {
                (async () => {
                    for await (const file of files) {
                        const parseFile = await this.readFileConvetToDatURL(file);
                        if (!parseFile) {
                            return this.handleMaximumFileLimit();
                        } else {
                            allDocs.push(parseFile);
                        }
                    }
                    resolve(allDocs);
                })();
            }
        });
    }

    async readFileConvetToDatURL(file: any) {
        return new Promise((resolve, _reject) => {
            const reader = new FileReader();
            reader.onload = async (_e: any) => {
                if (file.size < this.apisevice.setFileSize) {
                    const docData = {
                        result: reader.result,
                        files: file,
                    };
                    resolve(docData);
                } else {
                    resolve(false);
                }
            };
            reader.readAsDataURL(file);
        });
    }

    async handleMaximumFileLimit() {
        if (this.apisevice.setFileSize === 50000000) {
            this.toastrService.error(
                this.translate.instant('FILE_MUST_BE_LESS_THAN_50_MB')
            );
        } else {
            this.toastrService.error(
                this.translate.instant('FILE_MUST_BE_LESS_THAN_5_MB')
            );
        }
    }

    parseHTMLManipulation(message: any, mention_users: any, mention_groups: any) {
        const usersData: any = {};
        const groupsData: any = {};
        const domParser = new DOMParser();
        const parseHTMLDOMObject = domParser.parseFromString(message, 'text/html');
        const groups = parseHTMLDOMObject.querySelectorAll('[data-type="group"]');
        const users = parseHTMLDOMObject.querySelectorAll('[data-type="user"]');

        if (mention_users) {
            mention_users.forEach((user: any) => {
                usersData[user.user_id] = `${user.first_name} ${user.last_name}`;
            });
        }

        if (mention_groups) {
            mention_groups.forEach((group: any) => {
                groupsData[group.id] = group.group_name;
            });
        }

        _.templateSettings.interpolate = /\{\{(.+?)\}\}/g;    // NOSONAR

        groups.forEach((group) => {
            const template = _.template(group.nextSibling.textContent);
            group.nextSibling.textContent = groupsData[template(groupsData)];
        });

        users.forEach((user) => {
            const template = _.template(user.nextSibling.textContent);
            user.nextSibling.textContent = usersData[template(usersData)];
        });

        return parseHTMLDOMObject.documentElement.innerHTML;
    }

    calculateTime(time1: any, time2: any) {
    // Split time strings into hour, minute, and second components
        const [h1, m1, s1] = time1.split(':').map(Number);
        const [h2, m2, s2] = time2.split(':').map(Number);

        // Add time components together
        const totalSeconds = s1 + s2 ? s2 : 0;
        const totalMinutes = m1 + m2 + Math.floor(totalSeconds / 60);
        const totalHours = h1 + h2 + Math.floor(totalMinutes / 60);

        // Format the result as a string
        const hours = totalHours.toString().padStart(2, '0');
        const minutes = (totalMinutes % 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        const result = `${hours}:${minutes}:${seconds}`;

        return result;
    }

    getBooleanValue(value: any) {
        return value === 'true' || value === true || value === 1 || value === '1';
    }

    getBooleanValueWithFalse(value: any) {
        return value === 'true' || value === true || value === 1 || value === '1';
    }
    

    async formateBarReportData(arr: any) {
        const status_data: any = {};
        await arr.forEach((item: any) => {
            item?.data?.status_wise_counts?.forEach((status: any) => {
                if (!status_data[status.status_name]) {
                    status_data[status.status_name] = {};
                    status_data[status.status_name].data = [];
                    status_data[status.status_name].taskCount = [];
                }
                status_data[status.status_name].label = status?.status_name;
                status_data[status.status_name].fillColor = status?.status_color;
                status_data[status.status_name].backgroundColor = status?.status_color;
                status_data[status.status_name].borderColor = status?.status_color;
                status_data[status.status_name].barThickness = 30;
                status_data[status.status_name].data.push(
                    parseFloat(status.percentage?.toFixed(1))
                );
                status_data[status.status_name].taskCount.push(
                    parseFloat(status.count?.toFixed(1))
                );
            });
        });
        const labelName: any = await arr.map((item: any) => item.name);
        return { labelName, status_data };
    }

    async formatePieReportData(arr: any) {
        await arr.forEach(async (data: any) => {
            data.data['labelName'] = await data?.data?.status_wise_counts?.map(
                (ele: any) => ele?.status_name
            );
            data.data['data_set'] = {
                data: await data?.data?.status_wise_counts?.map(
                    (ele: any) => ele?.percentage || 0
                ),
                taskCount:await data?.data?.status_wise_counts?.map(
                    (ele: any) => ele?.count || 0
                ),
                backgroundColor: await data?.data?.status_wise_counts?.map(
                    (ele: any) => ele?.status_color
                ),
                offset1: this.calculateOffset(
                    await data?.data?.status_wise_counts?.map((ele: any) =>
                        ele?.percentage
                            ? parseFloat(ele?.percentage?.toFixed(1))
                            : ele?.percentage || 0
                    )
                ),
                barThickness: 30,
            };
        });
        return arr;
    }

    calculateOffset(data: number[]): number {
        let currentoffSet = 0;
        const offset: any = [];
        let consecutiveBelowThreshold = 0; // Track consecutive data points below 1.5%

        for (const value of data) {
            if (value < 2) {
                offset.push(currentoffSet + 12);
                currentoffSet += 12; // Increase offset by 5px for each sequential data point below 1.5%
                consecutiveBelowThreshold += 1;
            } else {
                // If the sequence is broken, reset the offset
                offset.push(0);
                currentoffSet = 0;
                consecutiveBelowThreshold = 0; // Reset the consecutive count
            }
        }

        return offset;
    }

    async formateLineReportData(result: any[]) {
        const status_data: any = {};

        await result.forEach((item: any) => {
            item?.data?.status_wise_counts?.forEach((status: any) => {
                if (!status_data[status.status_name]) {
                    status_data[status.status_name] = {};
                    status_data[status.status_name].data = [];
                    status_data[status.status_name].taskCount = [];
                }
                status_data[status.status_name].label = status.status_name;
                (status_data[status.status_name].pointBackgroundColor =
          status.status_color);
                (status_data[status.status_name].barThickness = 30);
                status_data[status.status_name].borderColor = status.status_color;
                status_data[status.status_name].data.push(status.percentage);
                status_data[status.status_name].taskCount.push(status.count);
            });
        });
        const labelName: any = await result.map((item: any) => item.name);
        return { labelName, status_data };
    }

    async generateExcelFile_mutiple(
        array_of_dataset: any,
        array_of_chart: any,
        username: any
    ) {
        this.canvas = null;
        const tableCount = array_of_dataset.length;
        const chartCount = array_of_chart.length;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(username);
        const cellWidth = 64; // Width of each cell in Excel (in pixels)
        const cellHeight = 20; // Height of each cell in Excel (in pixels)
        let nextStartRow = 1;
        for (let index = 0; index < chartCount; index++) {
            this.canvas = null;
            await html2canvas(array_of_chart[index].nativeElement).then((canvas) => {
                // Do something with the canvas element
                this.canvas = canvas;
            });

            const context = this.canvas.getContext('2d');
            context.imageSmoothingEnabled = false;
            // Generate the chart image
            const chartImageBase64 = this.canvas.toDataURL('image/png', 1.0);
            // Add the image to the worksheet
            const imageId = workbook.addImage({
                base64: chartImageBase64,
                extension: 'png',
            });
            const imageWidth = this.canvas.width; // Width of the image (in pixels)
            const imageHeight = this.canvas.height; // Height of the image (in pixels)
            const startRow = Math.floor(imageHeight / cellHeight) + 1; // Calculate the starting row number
            const endColumn = Math.floor(imageWidth / cellWidth); // Calculate the ending column number
            worksheet.addImage(
                imageId,
                `A${nextStartRow}:${String.fromCharCode(64 + endColumn)}${
                    nextStartRow + startRow
                }`
            );
            worksheet.mergeCells(
                `A${nextStartRow}:${String.fromCharCode(64 + endColumn)}${
                    nextStartRow + startRow
                }`
            );
            nextStartRow = nextStartRow + startRow + 5;
        }
        nextStartRow = nextStartRow - 4;
        for (let index = 0; index < tableCount; index++) {
            worksheet.addRow([]);
            worksheet.addRow([array_of_dataset[index]?.tableName]);
            worksheet.addRow(['Total Task Count', array_of_dataset[index]?.data?.length || 0]);
            const maxCount = Math.max(array_of_dataset[index]?.performance_counts?.length || 0, array_of_dataset[index]?.status_counts?.length || 0);
            for (let i = 0; i < maxCount; i++) {
                const performanceCount = array_of_dataset[index]?.performance_counts[i] || { name: '', count: '' };
                const statusCount = array_of_dataset[index]?.status_counts[i] || { name: '', count: '' };
                worksheet.addRow([
                    statusCount.name, statusCount.count,'','','',
                    performanceCount.name, performanceCount.count
                ]);
            }
            worksheet.addRow([]);
            const keysOfFirstObject = array_of_dataset[index]?.header;
            worksheet.addRow(keysOfFirstObject);
            const data = array_of_dataset[index]?.data.map((obj: any) =>
                Object.values(obj)
            );
            for (let j = 0; j < array_of_dataset[index]?.data?.length; j++) {
                worksheet.addRow(data[j]);
            }
        }

        // Convert the workbook to a buffer
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            // Create a blob from the buffer
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const timeSpan = new Date().toISOString();
            const sheetName = 'Report';
            const fileName = `${sheetName}-${timeSpan}`;
            // Create a download link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.xlsx`;

            // Append the link to the document and trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    async generateExcelFile_with_pie_mutiple(array_of_dataset: any) {
        const workbook = new ExcelJS.Workbook();
        this.pieCanvas = null;
        for (const element of array_of_dataset) {
            this.pieCanvas = null;
            this.headerCanvas = null;
            const worksheet = workbook.addWorksheet(element['userName']);
            const cellWidth = 64; // Width of each cell in Excel (in pixels)
            const cellHeight = 20; // Height of each cell in Excel (in pixels)
            let nextStartRow = 1;
            // add header canvas element
            await html2canvas(element.headerChartElement.nativeElement).then(
                (canvas) => {
                    // Do something with the canvas element
                    this.headerCanvas = canvas;
                }
            );
            const chartImageBase64header = this.headerCanvas.toDataURL(
                'image/png',
                1.0
            );
            // Add the image to the worksheet
            const imageIdHeader = workbook.addImage({
                base64: chartImageBase64header,
                extension: 'png',
            });
            const imageWidthHeader = this.headerCanvas.width; // Width of the image (in pixels)
            const imageHeightHeader = this.headerCanvas.height; // Height of the image (in pixels)
            const startRowHeader = Math.floor(imageHeightHeader / cellHeight) + 1; // Calculate the starting row number
            const endColumnHeader = Math.floor(imageWidthHeader / cellWidth); // Calculate the ending column number
            worksheet.addImage(
                imageIdHeader,
                `A${nextStartRow}:${String.fromCharCode(64 + endColumnHeader)}${
                    nextStartRow + startRowHeader
                }`
            );
            worksheet.mergeCells(
                `A${nextStartRow}:${String.fromCharCode(64 + endColumnHeader)}${
                    nextStartRow + startRowHeader
                }`
            );
            nextStartRow = nextStartRow + startRowHeader + 1;

            await html2canvas(element.graph).then((canvas) => {
                this.pieCanvas = canvas;
            });
            const chartImageBase64 = this.pieCanvas.toDataURL('image/png', 1.0);
            const imageId = workbook.addImage({
                base64: chartImageBase64,
                extension: 'png',
            });
            const imageWidth = this.pieCanvas.width; 
            const imageHeight = this.pieCanvas.height;
            const startRow = Math.floor(imageHeight / cellHeight) + 1;
            const endColumn = Math.floor(imageWidth / cellWidth);
            worksheet.addImage(
                imageId,
                `A${nextStartRow}:${String.fromCharCode(64 + endColumn)}${
                    nextStartRow + startRow
                }`
            );
            worksheet.mergeCells(
                `A${nextStartRow}:${String.fromCharCode(64 + endColumn)}${
                    nextStartRow + startRow
                }`
            );
            nextStartRow = nextStartRow + startRow + 5;
            worksheet.addRow([]);
            worksheet.addRow([element?.userName]);
            worksheet.addRow(['Total Task Count', element.table?.length || 0]);
            const maxCount = Math.max(element?.performance_counts?.length || 0, element?.status_counts?.length || 0);
            for (let i = 0; i < maxCount; i++) {
                const performanceCount = element?.performance_counts[i] || { name: '', count: '' };
                const statusCount = element?.status_counts[i] || { name: '', count: '' };
                worksheet.addRow([
                    statusCount.name, statusCount.count,'','','',
                    performanceCount.name, performanceCount.count
                ]);
            }
            worksheet.addRow([]);
            const keysOfFirstObject = element.header;
            worksheet.addRow(keysOfFirstObject);
            const data = element.table.map((obj: any) => Object.values(obj));
            for (let j = 0; j < element.table.length; j++) {
                worksheet.addRow(data[j]);
            }
        }

        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const timeSpan = new Date().toISOString();
            const sheetName = 'Report';
            const fileName = `${sheetName}-${timeSpan}`;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.xlsx`;

      
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    async formateActivityReportData(arr: any) {
        const activity_data: any = {};
        arr.forEach((activity: any, index: any) => {
            if (activity?.data?.percetage > 0) {
                activity_data[activity.activity_name] = {
                    data: new Array(arr.length).fill(0),
                    fillColor: this.getColorForActivity(activity.activity_name),
                    backgroundColor: this.getColorForActivity(activity.activity_name),
                    borderColor: this.getColorForActivity(activity.activity_name),
                    maxBarThickness: 30,
                    name: activity.activity_name,
                    taskCount:new Array(arr.length).fill(0)
                };
                activity_data[activity.activity_name]['data'][index] = activity.data?.percetage;
                activity_data[activity.activity_name]['taskCount'][index] = activity?.count;
            }
        });
        return activity_data;
    }

    formateVerticalActivityReportData(arr: any) {
        const ver_activity_data: any = {};
        arr?.forEach((val: any, index: any) => {
            ver_activity_data[val?.first_name + ' ' + val?.last_name] = {
                data: new Array(arr.length).fill(0),
                fillColor: this.getColorForActivity(val.activity_name),
                backgroundColor: this.getColorForActivity(val.activity_name),
                borderColor: this.getColorForActivity(val.activity_name),
                barThickness: 15,
                taskCount:new Array(arr.length).fill(0)
            };
            ver_activity_data[val.first_name + ' ' + val.last_name]['data'][index] = val?.data?.percentage;
            ver_activity_data[val.first_name + ' ' + val.last_name]['taskCount'][index] = val?.data?.user_activity_count;
        });
        return ver_activity_data;
    }
    // backgroundColor: arr.map((item: any) => item?.color || "#148E96"),

    async formateActivityPieReportData(arr: any) {
        return {
            data: [
                {
                    data: arr.map((item: any) => item.data.percetage),
                    backgroundColor: arr.map((item: any) => {
                        const color = this.getColorForActivity(item.activity_name);
                        return color;
                    }),
                    offset1: this.calculateOffset(
                        arr.map((item: any) =>
                            item.data.percetage
                                ? parseFloat(item.data.percetage).toFixed(1)
                                : item.data.percetage
                        )
                    ),
                    barThickness: 25,
                },
            ],
            label: arr.map((item: any) => item.activity_name),
        };
    }

    getColorForActivity(activity: any) {
        let color: any;
        switch (activity) {
        case 'Added':
            color = '#dc3535';
            break;
        case 'Assignee Added':
            color = '#C88E1B';
            break;
        case 'Assignee Removed':
            color = '#12945E';
            break;
        case 'Edit Due date':
            color = '#594AB6';
            break;
        case 'Edit Task':
            color = '#148E96';
            break;
        case 'Updated Status':
            color = '#C26131';
            break;
        case 'Follower Added':
            color = '#616161';
            break;
        case 'Follower Removed':
            color = '#1D53B7';
            break;
        case 'Closed Task':
            color = '#A29500';
            break;
        case 'Reopen Task':
            color = '#319056';
            break;
        case 'Services Added':
            color = '#AB4FCC';
            break;
        case 'Services Updated':
            color = '#8F192D';
            break;
        case 'Services Removed':
            color = '#2B4001';
            break;
        case 'Priority Changed':
            color = '#6382B1';
            break;
        case 'Attachments':
            color = '#ffc107';
            break;
        case 'Comments':
            color = '#2196f3';
            break;
        default:
            color = '#8fbc8f';
            break;
        }
        return color;
    }

    async generateExcelFile_single_pie(
        array_of_dataset: any,
        array_of_chart: any,
        headerCanvas: any,
        _username: any
    ) {
        this.headerCanvas = null;
        this.pieCanvas = null;
        const tableCount = array_of_dataset.length;
        const workbook = new ExcelJS.Workbook();
        const worksheet: any = workbook.addWorksheet('Status Report');
        const cellWidth = 64; // Width of each cell in Excel (in pixels)
        const cellHeight = 20; // Height of each cell in Excel (in pixels)
        let nextStartRow = 1;
        if (
            headerCanvas.nativeElement.scrollWidth <
      array_of_chart[0].nativeElement.scrollWidth
        ) {
            headerCanvas.nativeElement.style['width'] =
        array_of_chart[0].nativeElement.scrollWidth + 200 + 'px';
        } else {
            headerCanvas.nativeElement.style['width'] =
        headerCanvas.nativeElement.scrollWidth + 'px';
        }

        // add header canvas element
        await html2canvas(headerCanvas.nativeElement).then((canvas) => {
            // Do something with the canvas element
            this.headerCanvas = canvas;
        });
        const chartImageBase64 = this.headerCanvas.toDataURL('image/png', 1.0);
        // Add the image to the worksheet
        const imageId = workbook.addImage({
            base64: chartImageBase64,
            extension: 'png',
        });
        const imageWidth = this.headerCanvas.width; // Width of the image (in pixels)
        const imageHeight = this.headerCanvas.height; // Height of the image (in pixels)
        const startRow = Math.floor(imageHeight / cellHeight) + 1; // Calculate the starting row number
        const endColumn = this.getColumnLetter(Math.floor(imageWidth / cellWidth)); // Calculate the ending column number

        headerCanvas.nativeElement.style['width'] = '100%';
        worksheet.addImage(
            imageId,
            `A${nextStartRow}:${endColumn}${nextStartRow + startRow}`
        );
        worksheet.mergeCells(
            `A${nextStartRow}:${endColumn}${nextStartRow + startRow}`
        );
        nextStartRow = nextStartRow + startRow + 5;

        for (let index = 0; index < tableCount; index++) {
            worksheet.addRow([]);
            worksheet.addRow([array_of_dataset[index]?.tableName]);
            worksheet.addRow(['Total Task Count', array_of_dataset[index]?.data?.length || 0]);
            const keysOfFirstObject = array_of_dataset[index]?.header;
            worksheet.addRow(keysOfFirstObject);
            const data = array_of_dataset[index]?.data.map((obj: any) =>
                Object.values(obj)
            );
            for (let j = 0; j < array_of_dataset[index]?.data.length; j++) {
                worksheet.addRow(data[j]);
            }
        }

        // Convert the workbook to a buffer
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            // Create a blob from the buffer
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const timeSpan = new Date().toISOString();
            const sheetName = 'Report';
            const fileName = `${sheetName}-${timeSpan}`;
            // Create a download link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.xlsx`;

            // Append the link to the document and trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    getColumnLetter(columnNumber: any) {
    // Convert the column number to the corresponding column letter(s)
        let columnLetter = '';
        while (columnNumber > 0) {
            const remainder = (columnNumber - 1) % 26;
            columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
            columnNumber = Math.floor((columnNumber - 1) / 26);
        }
        return columnLetter;
    }

    async setCurrentDateTime(date:any){
        const currentDate = new Date();
        const originalDate = new Date(date);
        originalDate.setHours(currentDate.getHours());
        originalDate.setMinutes(currentDate.getMinutes());
        originalDate.setSeconds(currentDate.getSeconds());
        return originalDate;
    }

    trimBrackets(str:string){
        const openingParenthesisIndex = str.indexOf('(');
        const result:any = openingParenthesisIndex !== -1 ? str.substring(0, openingParenthesisIndex).trim() : str;
        return result;
    }

    capitalizeFormateData(keyArr:any, arr:any){
        for (const obj of arr) {
            for (const key of keyArr) {
                if (obj.hasOwnProperty(key)) {
                    obj[key] = (obj[key] !== null || '-' || undefined) ? obj[key].charAt(0).toUpperCase() + obj[key].slice(1) : '-';
                }
            }
        }
        return arr;
    }
}
